import styles from './Layout.module.scss'

const Content = ({children}) => {
  return (
    <div className={styles.Content}>{children}</div>
  );
}

Content.propTypes = {

};

export default Content;
