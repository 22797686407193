
export const formatNumber = (phone) => {
  return phone.replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3');
}

export const formatDate = (date, locale) => (new Date(date)).toLocaleDateString(locale, {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
});
