import styles from './Navbar.module.scss'
import classNames from "classnames";

const MenuToggle = ({active, onClick}) => {
  return (
    <button aria-label="menu" onClick={onClick} className={classNames([
      styles.toggleButton,
      {[styles.toggleButtonActive]: active}
    ])}>
      <span></span>
      <span></span>
      <span></span>
    </button>
  );
}

export default MenuToggle;
