import styles from "./Footer.module.scss";
import globalStyles from "./../Layout/Layout.module.scss";
import layoutStyles from "../Layout/Layout.module.scss";
import Translator from '../Translator/Translator';
import {EMAIL, PAGE, PHONE} from "../../services/constants";
import {formatNumber} from "../../services/utilities";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import NavLinkLocale from "../NavLinkLocale";


export default function Footer() {
  const { t } = useTranslation();
  return (
    <footer className={styles.footer}>
      <div className={`${layoutStyles.Container} ${styles.footerColumns}`}>
          <div className={classNames([styles.footerColumn, globalStyles.hideSm])}>
            <img src="/logo_white_text.svg" height={82} width={186} />
          </div>
          <div className={classNames([styles.footerColumn, styles.translatorFooter, globalStyles.hideMd])}>
            <Translator />
          </div>
          <div className={styles.footerColumn}>
            <ul>
              <li>
                <NavLinkLocale to="/">{t('Główna')}</NavLinkLocale>
              </li>
              <li>
                <NavLinkLocale to="/offer">{t('Oferta')}</NavLinkLocale>
              </li>
              {/*<li>*/}
              {/*  <NavLinkLocale to="/about">{t('Blog')}</NavLinkLocale>*/}
              {/*</li>*/}
              <li>
                <NavLinkLocale to="/contact">{t('Kontakt')}</NavLinkLocale>
              </li>
            </ul>
          </div>
          <div className={styles.footerColumn}>
            <ul>
              <li>
                <img src="/phone-svgrepo-com.svg" height={16} width={16} />
                <a href={`tel:+48${PHONE}`}>{formatNumber(PHONE)}</a>
              </li>
              <li>
                <img src="/email-envelope-svgrepo-com.svg" height={16} width={16} />
                <a href={`mailto:${EMAIL}`}>{EMAIL}</a>
              </li>
              <li>
                <img src="/globe-svgrepo-com.svg" height={16} width={16} />
                <a href={`https://${PAGE}`}>{PAGE}</a>
              </li>
            </ul>
          </div>
        </div>
      <div className={`${layoutStyles.Container} ${styles.footerPolicies}`}>
        <NavLinkLocale to="/private-policy">{t('Polityka prywatności')}</NavLinkLocale>
        {/*{' | '}*/}
        {/*<NavLinkLocale to="/cookies-policy">{t('Polityka Cookies')}</NavLinkLocale>*/}
        {/*{' | '}*/}
        {/*<NavLinkLocale to="/rules">{t('Regulamin')}</NavLinkLocale>*/}
      </div>
    </footer>
  )
}
