
import layoutStyles from "../Layout/Layout.module.scss";
import styles from './Navbar.module.scss'
import {EMAIL, PHONE} from "../../services/constants";
import {formatNumber} from "../../services/utilities";
import {forwardRef, useRef, useState} from "react";
import MenuToggle from "./MenuToggle";
import classNames from "classnames";
import useOutsideClick from "../../hooks/useOutsideClick";
import NavLinkLocale from "../NavLinkLocale";
import NavLinkSetLanguage from "../NavLinkSetLanguage";
import {useTranslation} from "react-i18next";


const Navbar = () => {
  const { t } = useTranslation();
  const [active, setActive] = useState(false);
  const menuRef = useRef(null);

  useOutsideClick(menuRef, () => {
    setActive(false);
  })


  const LinkWithMenuClose = forwardRef(({ href, children }, ref) => {
    return (
      <a href={href} onClick={() => setActive(false)} ref={ref}>
        {children}
      </a>
    )
  })
  LinkWithMenuClose.displayName = 'LinkWithMenuClose';


  return (
    <div className={styles.navbar}>
      <nav className={layoutStyles.Container}>
        <div className={styles.navbarLocales}>
          <NavLinkSetLanguage
            locale="pl"
          >
              <img src="/flags/pl.svg" height={32} width={32} title="Polski" alt="Polski" />
          </NavLinkSetLanguage>
          {' | '}
          <NavLinkSetLanguage locale="uk">
              <img src="/flags/ua.svg" height={32} width={32} title="Українська" alt="Українська" />
          </NavLinkSetLanguage>
        </div>

        <div ref={menuRef}>
          <MenuToggle active={active} onClick={() => {
            setActive((prev) => !prev)
          }}/>
          <ul
            className={classNames([
              styles.navbarMenu,
              {[styles.navbarMenuActive]: active}
            ])}
          >
            <li>
              <NavLinkLocale onClick={() => setActive(false)} to="/">
                {t('Główna')}
              </NavLinkLocale>
            </li>
            <li>
              <NavLinkLocale onClick={() => setActive(false)} to="/offer">
                {t('Oferta')}
              </NavLinkLocale>
            </li>
            <li>
              <NavLinkLocale onClick={() => setActive(false)} to="/contact">
                {t('Kontakt')}
              </NavLinkLocale>
            </li>
            <li>
              <NavLinkLocale onClick={() => setActive(false)} to="/about" >
                {t('O mnie')}
              </NavLinkLocale>
            </li>
            <li>
              <a href={`tel:+48${PHONE}`}>{formatNumber(PHONE)}</a>
            </li>
            <li>
              <a href={`mailto:${EMAIL}`}>{EMAIL}</a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  )
};
export default Navbar;
