import {NavLink, useLocation} from "react-router-dom";
import i18n from "../i18n";

const NavLinkSetLanguage = ({
                              otherProps,
                              children,
                              locale: newLocale,
                            }) => {
  const {pathname} = useLocation();
  const getTo = () => {
    const localeRegex = /^\/uk\/?/
    if (newLocale === 'pl') {
      if (pathname.match(localeRegex)) {
        return pathname.replace(localeRegex, '/')
      }
      return pathname;
    } else {
      if (pathname.match(localeRegex)) {
        return pathname
      }
      return `/${newLocale}${pathname}`
    }
  }
  return <NavLink {...otherProps} to={getTo()} onClick={() => i18n.changeLanguage(newLocale)}>{children}</NavLink>
}

export default NavLinkSetLanguage
