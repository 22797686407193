import Content from "./Content";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

export default function Layout({ children }) {
  return (
    <>
      <Navbar />
      <Content>
        {children}
      </Content>
      <Footer />
    </>
  )
}
