import styles from "./Translator.module.scss";


export default function Translator({ className }) {
  return (
    <div className={`${styles.translator} ${className || ''}`}>
      tłumacz przysięgły
      <br/>
      języka ukraińskiego
      <hr/>
      присяжний перекладач
      <br/>
      української мови
    </div>
  )
}
