import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";

const NavLinkLocale = ({
                         to,
                         children,
                         ...otherProps
                       }) => {
  const {i18n: { language }} = useTranslation();
  const getTo = () => {
    if (['uk'].includes(language)) {
      return `/${language}${to === '/' ? '' : to}`
    }
    return to;
  }
  return <NavLink {...otherProps} to={getTo()}>{children}</NavLink>
}

export default NavLinkLocale;
